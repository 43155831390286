<template>
  <div class="loading">
    <p>
      <img src="/img/loading-auto-login.svg" alt="">
      <span>{{ t('autoLogin.tips', { dot: text }) }}</span>
    </p>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useDialog } from '../utils/dialog.js'
import { autoLoginApi } from '../api/index.js'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, reactive, toRefs } from 'vue'

export default {
  name: 'AutoLogin',
  setup() {
    const { t } = useI18n()
    const Store = useStore()
    const Route = useRoute()
    const Router = useRouter()
    const Dialog = useDialog()
    const data = reactive({
      auth: Route.query.auth,
      text: ''
    })

    onMounted(() => {
      if (data.auth === undefined) {
        Router.push({ name: 'Login' })
        return
      }

      animaText()
      setTimeout(() => {
        autoLogin()
      }, 3000)
    })

    const animaText = () => {
      let count = 0
      setInterval(() => {
        if (count === 1) {
          data.text = ''
        } else if (count === 2) {
          data.text = '.'
        } else if (count === 3) {
          data.text = '. .'
        } else if (count === 4) {
          data.text = '. . .'
          count = 0
        }
        count++
      }, 500)
    }

    const autoLogin = () => {
      autoLoginApi({
        auth: data.auth
      }).then(succ => {
        if (succ.code === 10000) {
          Store.commit('set', { userLanguage: succ.data.language })
          Store.commit('setPanelNav', {
            nickname: succ.data.nickname,
            userAvatar: succ.data.pic
          })
          Router.push({ name: 'Panel' })
          return
        }
        let msg = ''
        if (succ.code === 10001 || succ.code === 10002) {
          msg = t('autoLogin.autoLoginApi.paramError')
        }
        if (succ.code === 10003) {
          msg = t('autoLogin.autoLoginApi.linkExpired')
        }
        Dialog({
          closeable: false,
          content: msg,
          btns: [{ text: t('ok'), click: () => Router.push({ name: 'Login' }) }]
        })
      })
    }

    return { ...toRefs(data), t }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  display: table;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  p {
    color: #505050;
    display: table-cell;
    vertical-align: middle;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    img {
      width: 140px;
      margin: 0 auto;
    }
  }
}
</style>
